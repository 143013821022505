import { PrintFriendlyGridTable, theme } from "@project/common"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const ContentTitle = styled.div`
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.colors.border};
  font-weight: 500;
`
export const EntityItemTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .employment_item_content {
    border: 1px solid ${theme.colors.border};
    padding: 10px 16px;
    .item_content-title {
      padding-bottom: 10px;
    }
    #employment_item_table {
      width: 100%;
      border-collapse: collapse;
      tbody {
        tr {
          page-break-inside: avoid !important;
          td {
            border: 1px solid ${theme.colors.border};
            padding: 10px;
          }
          .label {
            width: 25%;
            background-color: ${theme.colors.background};
          }
        }
      }
    }
  }
`
interface Field {
  label?: string
  value?: any
  items?: any[]
  type?: string
  key?: string
}
interface FiledProps {
  label: string
  value: string | any[] | ReactNode
  extra?: any
  assessment_employment_item?: Field[]
  type?: string
  key?: string
}
interface IProps {
  fields: FiledProps[]
  title?: string
  cardTitle?: string
}
export const AssessmentOtherFieldsContent = ({ fields, title }: IProps) => {
  const { t } = useTranslation()
  return (
    <div>
      {title && <ContentTitle>{t(title)}</ContentTitle>}
      <PrintFriendlyGridTable fields={fields} />
    </div>
  )
}
