import { Button, FACILITY_TYPES, theme } from "@project/common"
import { Flex, Typography } from "antd"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { FULL_DATE_FORMAT } from "../../../constants"
const AssessmentDetailBtnInfoWrapper = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
`
export const AssessmentDetailBtnInfo = ({
  show = "info",
  onClick,
  data,
  hasEditAccess,
  hasPDF = true,
}: {
  show?: "btn" | "info"
  onClick: (action: "print" | "pdf" | "back" | "edit") => void
  data?: any
  hasEditAccess: boolean
  hasPDF?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AssessmentDetailBtnInfoWrapper>
      <Flex gap={12} wrap={"wrap"}>
        <Button
          btnText={t("<<Back")}
          shape={"round"}
          maxHeight={"36px"}
          onClick={() => onClick("back")}
        />
        {hasPDF && (
          <Button
            btnText={t(t("Download PDF"))}
            shape={"round"}
            type={"primary"}
            disabled={false}
            iconType={"pdf"}
            onClick={() => onClick("pdf")}
            maxHeight={"36px"}
          />
        )}
        <Button
          btnText={t("Print")}
          type={"primary"}
          shape={"round"}
          iconType={"print"}
          disabled={false}
          onClick={() => onClick("print")}
          maxHeight={"36px"}
        />
      </Flex>
      {show === "info" ? (
        <Flex vertical>
          <Typography.Text>
            <b>{t("Facility name")}</b>
            {":"}
            <Typography.Text style={{ marginLeft: "8px" }}>
              {data?.user?.active_contract?.facility?.facility_name || ""}
            </Typography.Text>
          </Typography.Text>
          <Typography.Text>
            <b>
              {t("Use service")} {":"}
            </b>
            <Typography.Text style={{ marginLeft: "8px" }}>
              {t(
                FACILITY_TYPES?.find(
                  (type) =>
                    type?.value === data?.user?.user_certificate?.service_type,
                )?.label || "",
              )}
            </Typography.Text>
          </Typography.Text>
          <Typography.Text>
            <b>
              {t("Created date")} {":"}
            </b>
            <Typography.Text style={{ marginLeft: "8px" }}>
              {dayjs(data?.created_datetime).format(FULL_DATE_FORMAT)}
            </Typography.Text>
          </Typography.Text>
        </Flex>
      ) : null}
      {show === "btn" ? (
        <Button
          btnText={t("Edit assessment")}
          btnBg={theme.colors["action-400"]}
          shape={"round"}
          iconType={"blue-pencil"}
          onClick={() => onClick("edit")}
          maxHeight={"36px"}
          disabled={!hasEditAccess}
        />
      ) : null}
    </AssessmentDetailBtnInfoWrapper>
  )
}
