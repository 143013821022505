import { useQuery } from "react-query"
import { getAllWageRevenues } from "../services"

export const useWageRevenueDataFetch = (params: {
  tab?: string
  facility_id?: any
  date?: any
  year: number
  month: number
}) => {
  const {
    data: wageRevenueData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["wage-revenue-data", params],
    queryFn: () => getAllWageRevenues(params),
    retry: 1,
    refetchOnWindowFocus: false,
  })
  return { wageRevenueData, isLoading: isLoading || isFetching }
}
