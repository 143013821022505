import { useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
interface NonCssAttributes extends React.CSSProperties {
  size?: string
  contentRef: any | null
  pageStyle?: any
}
export const usePrint = ({ contentRef, pageStyle }: NonCssAttributes) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const promiseResolveRef = useRef(null)
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])
  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
    pageStyle: `
      @page{
      ${pageStyle};
      }
    `,
  })
  return { handlePrint, isPrinting }
}
