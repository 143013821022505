import {
  Breadcrumb,
  Button,
  Card,
  PageTitle,
  theme,
  usePrint,
} from "@project/common"
import { Flex, Skeleton, Typography } from "antd"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import { FAILURE_TYPES, FULL_DATE_FORMAT } from "../../../constants"
import { AuthContext } from "../../../context"
import { useCommSeparateOccupation } from "../../../hooks"
import {
  hasPermissionForFacility,
  UserManagementPermission,
} from "../../../utils/PermissionKeys"
import { AssessmentEntityItemFields } from "../common/AssessmentEntityItemFields"
import { AssessmentDetailLoader } from "../common/AssessmentLoader"
import { AssessmentDetailBtnInfo } from "./AssessmentDetailBtnInfo"
import { AssessmentDetailGeneralContent } from "./AssessmentDetailGeneralContent"
import { AssessmentDetailPrintContent } from "./AssessmentDetailPrintContent/AssessmentDetailPrintContent"

export const AssessmentDetailContainer = ({
  isLoading,
  assessmentDetail,
  assessmentSetting,
}: {
  isLoading
  assessmentSetting
  assessmentDetail
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const { permissions, isOwner } = useContext(AuthContext)
  const printContentRef = React.useRef<HTMLDivElement>(null)
  const title = t("{{username}} assessment", {
    username: assessmentDetail?.user?.username || "",
  })

  const { name, occupations } = useCommSeparateOccupation({
    staff_id: assessmentDetail?.staff_id,
  })
  const contentRef = useRef<HTMLDivElement>(null)
  const { isPrinting, handlePrint } = usePrint({
    contentRef,
    pageStyle: `margin:6mm`,
  })
  const staffInfo = () => (
    <Flex gap={16} wrap={"wrap"}>
      <Typography.Text>{name}</Typography.Text>
      <Typography.Text>
        {t("Occupation")}
        {" : "}
        {occupations}
      </Typography.Text>
    </Flex>
  )

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        UserManagementPermission,
        "write",
        assessmentDetail?.facility_id,
      ),
    [isOwner, permissions, assessmentDetail],
  )

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <Link href={"/"}>{t("Home")}</Link>,
          },
          {
            title: <Link href={"/assessment"}>{t("Assessment")}</Link>,
          },
          {
            title: t("{{username}} assessment", {
              username: assessmentDetail?.user?.username || "",
            }),
          },
        ]}
      />
      <PageTitle
        icon={<img src={"/assets/icons/data2.svg"} alt={"icon"} />}
        title={title}
        extra={
          <Button
            btnText={t("Edit assessment")}
            btnBg={theme.colors["action-400"]}
            shape={"round"}
            iconType={"blue-pencil"}
            disabled={!hasEditAccess || isLoading}
            onClick={() =>
              router?.push(`/assessment/edit/${router?.query?.id}`)
            }
            maxHeight={"36px"}
          />
        }
      />
      <Card title={isLoading ? <Skeleton.Button active /> : title}>
        {isLoading ? (
          <AssessmentDetailLoader />
        ) : (
          <Flex vertical gap={32}>
            <AssessmentDetailBtnInfo
              data={assessmentDetail || {}}
              show={"info"}
              onClick={(action) => {
                if (action === "back") {
                  router.push("/assessment")
                }
                if (action === "print") {
                  handlePrint()
                }
              }}
              hasEditAccess={hasEditAccess}
              hasPDF={false}
            />

            <Flex vertical gap={32} ref={printContentRef}>
              <AssessmentDetailGeneralContent
                fields={[
                  {
                    label: t("Username"),
                    value: assessmentDetail?.user?.username || "",
                  },
                  {
                    label: t("No of Assessment"),
                    value: assessmentDetail?.creation_number || "",
                  },
                  {
                    label: t("Service start date"),
                    value: assessmentDetail?.user?.user_certificate?.start_date
                      ? dayjs(
                          assessmentDetail?.user?.user_certificate?.start_date,
                        )?.format(FULL_DATE_FORMAT)
                      : "",
                  },
                  {
                    label: t("Failure type"),
                    value: FAILURE_TYPES.filter(
                      (_, index) =>
                        assessmentDetail?.user?.failure_types?.includes(index),
                    )
                      .map((d) => t(d))
                      .join(", "),
                  },
                ]}
              />
              {assessmentSetting?.map((settings) => (
                <>
                  <Flex vertical gap={16}>
                    {/* //for work settings */}
                    {settings?.title?.toLowerCase() === "work" && (
                      <>
                        {settings?.assessment_category?.map((category) => {
                          if (category?.assessment_item?.length > 0) {
                            return (
                              <AssessmentDetailGeneralContent
                                key={category?.id}
                                title={category?.title || ""}
                                fields={category?.assessment_item?.map(
                                  (item) => ({
                                    label: item?.title || "",
                                    value: item?.answer || "",
                                    type:
                                      item?.title ===
                                      "Disclosure of disability information"
                                        ? "checkbox"
                                        : null,

                                    assessment_employment_item:
                                      item?.title ===
                                      "Employment history/training history, etc."
                                        ? assessmentDetail?.assessment_employment_item ||
                                          []
                                        : null,
                                  }),
                                )}
                              />
                            )
                          }
                          return null
                        })}
                      </>
                    )}
                    {/* //for support and aptitude/skills settings */}
                    {(settings?.title?.toLowerCase() === "support" ||
                      settings?.title?.toLowerCase() === "aptitude/skills") && (
                      <AssessmentEntityItemFields
                        assessment_setting_id={settings?.id}
                        assessmentId={settings?.id}
                        mode={"detail"}
                        title={settings?.title}
                        key={settings?.id}
                        entityItems={settings?.assessment_category || []}
                        assessment_entity_item={
                          assessmentDetail?.assessment_entity_item || []
                        }
                      />
                    )}
                    {/* //for other settings */}
                    {settings?.title?.toLowerCase() === "other" && (
                      <>
                        {settings?.assessment_category?.map((category) => {
                          if (category?.assessment_item?.length > 0) {
                            return (
                              <AssessmentDetailGeneralContent
                                key={category?.id}
                                title={category?.title || ""}
                                fields={category?.assessment_item?.map(
                                  (item) => ({
                                    label: item?.title || "",
                                    value: item?.answer || "",
                                  }),
                                )}
                              />
                            )
                          }
                          return null
                        })}
                      </>
                    )}
                  </Flex>
                </>
              ))}

              <AssessmentDetailGeneralContent
                fields={[
                  {
                    label: "Author",
                    value: name,
                    extra: staffInfo(),
                    key: "author",
                    type: "textArea",
                  },
                ]}
              />
            </Flex>
            <AssessmentDetailBtnInfo
              show={"btn"}
              onClick={(action) => {
                if (action === "back") {
                  router.push("/assessment")
                }
                if (action === "print") {
                  handlePrint()
                }
                if (action === "edit") {
                  router?.push(`/assessment/edit/${router?.query?.id}`)
                }
              }}
              hasEditAccess={hasEditAccess}
              hasPDF={false}
            />
          </Flex>
        )}
      </Card>
      {isPrinting && (
        <div style={{ display: "none" }}>
          <AssessmentDetailPrintContent
            title={title}
            ref={contentRef}
            staff_name={name || null}
            extra={staffInfo()}
            assessmentData={{
              assessmentDetail,
              assessmentSetting,
            }}
          />
        </div>
      )}
    </>
  )
}
