import { InputAreaField, Radio, Table, theme } from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { AssessmentTableDataChangeProps } from "../../../types"

const Wrapper = styled(Flex)`
  .other_content_head__title {
    border-bottom: 1px solid ${theme.colors.border};
    padding: 8px 0;
    font-weight: 500;
  }
`

const AssessmentEntityItemFieldsTable = ({
  categoryTitle,
  assessmentItems,
  handleAssessmentEntryItemChanged,
  mode,
}: {
  categoryTitle: string
  assessmentItems: any[]
  mode: "detail" | "add" | "edit"
  handleAssessmentEntryItemChanged?: (
    values: AssessmentTableDataChangeProps,
  ) => void
}) => {
  const { t } = useTranslation()
  const columns: ColumnsType<any[]> = [
    {
      key: 1,
      title: categoryTitle || "",
      align: "center",
      width: 140,
      render: (row) => <Typography.Text>{t(row?.title) || ""}</Typography.Text>,
    },
    {
      key: 2,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Can ")}</span>,
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          name={"answer"}
          value={row?.answer || null}
          checked={row?.answer === "1" || null}
          disabled={mode === "detail"}
          onChange={({ target: { checked } }) => {
            if (mode !== "detail") {
              handleAssessmentEntryItemChanged({
                item_id: row?.id,
                value: checked ? "1" : null,
                key: "answer",
              })
            }
          }}
        />
      ),
    },
    {
      key: 3,
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("need a little help")}</span>
      ),
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          name={"answer"}
          value={row?.answer || null}
          checked={row?.answer === "2" || null}
          disabled={mode === "detail"}
          onChange={({ target: { checked } }) => {
            if (mode !== "detail") {
              handleAssessmentEntryItemChanged({
                item_id: row?.id,
                value: checked ? "2" : null,
                key: "answer",
              })
            }
          }}
        />
      ),
    },
    {
      key: 4,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("need help")}</span>,
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          name={"answer"}
          value={row?.answer || null}
          checked={row?.answer === "3" || null}
          disabled={mode === "detail"}
          onChange={({ target: { checked } }) => {
            if (mode !== "detail") {
              handleAssessmentEntryItemChanged({
                item_id: row?.id,
                value: checked ? "3" : null,
                key: "answer",
              })
            }
          }}
        />
      ),
    },
    {
      key: 5,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Can not")}</span>,
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          name={"answer"}
          value={row?.answer || null}
          checked={row?.answer === "4" || null}
          disabled={mode === "detail"}
          onChange={({ target: { checked } }) => {
            if (mode !== "detail") {
              handleAssessmentEntryItemChanged({
                item_id: row?.id,
                value: checked ? "4" : null,
                key: "answer",
              })
            }
          }}
        />
      ),
    },
    {
      key: 6,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Remarks")}</span>,
      align: "center",
      width: 400,
      render: (row) =>
        mode === "detail" ? (
          row?.remarks || "--"
        ) : (
          <InputAreaField
            name={"remarks"}
            width={"400px"}
            value={row?.remarks}
            onChange={({ target: { value } }) => {
              handleAssessmentEntryItemChanged({
                item_id: row?.id,
                value: value,
                key: "remarks",
              })
            }}
          />
        ),
    },
  ]
  return (
    <Table
      columns={columns}
      headerBg={theme.colors.disabled}
      dataSource={assessmentItems || []}
      scroll={{ x: 800 }}
      pagination={false}
    />
  )
}
export const AssessmentEntityItemFields = ({
  title,
  entityItems,
  handleAssessmentEntryItemChanged,
  mode,
  assessment_setting_id,
}: {
  title: string
  entityItems: any[]
  handleAssessmentEntryItemChanged?: (
    values: AssessmentTableDataChangeProps,
  ) => void
  mode: "detail" | "add" | "edit"
  assessment_entity_item?: any[]
  assessmentId: number
  assessment_setting_id: number
}): JSX.Element => {
  const { t } = useTranslation()
  const items_length = entityItems?.filter(
    (v) => v?.assessment_item?.length > 1,
  )
  return items_length?.length > 1 ? (
    <Wrapper vertical gap={16}>
      <div className={"other_content_head__title"}>{t(title)}</div>
      <Flex vertical gap={16}>
        {entityItems?.map((item, idx) => {
          return item?.assessment_item?.length ? (
            <AssessmentEntityItemFieldsTable
              mode={mode}
              handleAssessmentEntryItemChanged={(values: any) => {
                handleAssessmentEntryItemChanged({
                  ...values,
                  id: assessment_setting_id,
                  category_id: item?.id,
                })
              }}
              key={idx}
              categoryTitle={t(item?.title) || ""}
              assessmentItems={item?.assessment_item || []}
            />
          ) : null
        })}
      </Flex>
    </Wrapper>
  ) : null
}
