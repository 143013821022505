import { getLanguage } from "@project/common"
import { useFetchAllStaff } from "./useFetchData"

export const useCommSeparateOccupation = ({
  staff_id,
  className,
}: {
  staff_id: number
  className?: string
}) => {
  const lng = getLanguage()
  const { staffData } = useFetchAllStaff({
    pageSize: "Infinity",
    page: 1,
  })
  const staff = staffData?.find((staff) => staff?.id === staff_id)
  const occupations = staff?.staff_facility?.map((val, i) => (
    <span key={i} className={className || ""}>
      {lng === "en"
        ? val?.occupation?.occupation_name_eng
        : val?.occupation?.occupation_name}
      {val?.occupation2?.occupation_name
        ? `, ${
            lng === "en"
              ? val?.occupation2?.occupation_name_eng
              : val?.occupation2?.occupation_name
          }`
        : ""}
      {staff?.staff_facility?.length > 1 &&
        staff?.staff_facility?.length - 1 !== i && <em>{", "}</em>}
    </span>
  ))
  return {
    occupations,
    name: staff?.label || staff?.staff_name,
  }
}
