import {
  FACILITY_TYPES,
  PrintFriendlyTable,
  Radio,
  theme,
} from "@project/common"
import { Flex, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  FAILURE_TYPES,
  FULL_DATE_FORMAT,
  YM_FORMAT,
} from "../../../../constants"
import { AssessmentOtherFieldsContent } from "./AssessmentOtherFieldsContent"

export const AssessmentDetailPrintContent = React.forwardRef(
  (
    {
      staff_name,
      title,
      assessmentData: { assessmentDetail, assessmentSetting },
      extra,
    }: {
      extra?: any
      assessmentData: {
        assessmentDetail: any
        assessmentSetting: any[]
      }
      title: string
      staff_name?: string | null
    },
    ref: any,
  ): JSX.Element => {
    const { t } = useTranslation()
    return (
      <Flex ref={ref} vertical gap={8}>
        <Flex gap={9} justify={"space-between"}>
          <Typography.Text strong> {title}</Typography.Text>
          <Flex vertical>
            <Typography.Text>
              <b>{t("Facility name")}</b>
              {":"}
              <Typography.Text style={{ marginLeft: "8px" }}>
                {assessmentDetail?.user?.active_contract?.facility
                  ?.facility_name || ""}
              </Typography.Text>
            </Typography.Text>
            <Typography.Text>
              <b>
                {t("Use service")} {":"}
              </b>
              <Typography.Text style={{ marginLeft: "8px" }}>
                {t(
                  FACILITY_TYPES?.find(
                    (type) =>
                      type?.value ===
                      assessmentDetail?.user?.user_certificate?.service_type,
                  )?.label || "",
                )}
              </Typography.Text>
            </Typography.Text>
            <Typography.Text>
              <b>
                {t("Created date")} {":"}
              </b>
              <Typography.Text style={{ marginLeft: "8px" }}>
                {dayjs(assessmentDetail?.created_datetime).format(
                  FULL_DATE_FORMAT,
                )}
              </Typography.Text>
            </Typography.Text>
          </Flex>
        </Flex>
        <AssessmentOtherFieldsContent
          fields={[
            {
              label: t("Username"),
              value: assessmentDetail?.user?.username || "",
            },
            {
              label: t("No of Assessment"),
              value: assessmentDetail?.creation_number || "",
            },
            {
              label: t("Service start date"),
              value: assessmentDetail?.user?.user_certificate?.start_date
                ? dayjs(
                    assessmentDetail?.user?.user_certificate?.start_date,
                  )?.format(FULL_DATE_FORMAT)
                : "",
            },
            {
              label: t("Failure type"),
              value: FAILURE_TYPES.filter(
                (_, index) =>
                  assessmentDetail?.user?.failure_types?.includes(index),
              )
                .map((d) => t(d))
                .join(", "),
            },
          ]}
        />
        {assessmentSetting?.map((setting) => (
          <Flex vertical gap={16} key={setting?.id}>
            {setting?.assessment_category?.map((category) => (
              <>
                {category?.assessment_item?.length > 0 ? (
                  <>
                    {setting?.title?.toLowerCase() === "work" && (
                      <AssessmentOtherFieldsContent
                        title={t(category?.title || "")}
                        fields={category?.assessment_item?.map((item) => ({
                          label: t(item?.title || ""),
                          type:
                            item?.title ===
                            "Disclosure of disability information"
                              ? "checkbox"
                              : undefined,
                          value:
                            item?.title !==
                            "Employment history/training history, etc."
                              ? item?.answer || ""
                              : assessmentDetail?.assessment_employment_item?.map(
                                  (item, index) => ({
                                    title: t(`就労歴・訓練歴等${index + 1}`),
                                    items: [
                                      {
                                        label: t("Start Date"),
                                        value: item?.start_date
                                          ? dayjs(item?.start_date)?.format(
                                              YM_FORMAT,
                                            )
                                          : "---",
                                        type: "date",
                                      },
                                      {
                                        label: t("End date"),
                                        value: item?.end_date
                                          ? dayjs(item?.end_date)?.format(
                                              YM_FORMAT,
                                            )
                                          : "---",
                                      },
                                      {
                                        label: t("Company name/facility name"),
                                        value: item?.company_name || "---",
                                      },
                                      {
                                        label: t("Detail"),
                                        value: item?.detail || "---",
                                      },
                                    ],
                                  }),
                                ),
                        }))}
                      />
                    )}
                  </>
                ) : null}
              </>
            ))}
            {(setting?.title?.toLowerCase() === "support" ||
              setting?.title?.toLowerCase() === "aptitude/skills") && (
              <>
                {setting?.assessment_category?.filter(
                  (v) => v?.assessment_item?.length > 0,
                )?.length > 0 ? (
                  <Flex vertical gap={8}>
                    <div
                      style={{
                        borderBottom: `1px solid ${theme.colors.border}`,
                        paddingBottom: "8px",
                      }}
                    >
                      {t(setting?.title || "")}
                    </div>
                    {setting?.assessment_category?.map((category) =>
                      category?.assessment_item?.length > 0 ? (
                        <AssessmentEntityItemTable
                          key={category?.id}
                          categoryTitle={category?.title || ""}
                          assessmentItems={category?.assessment_item || []}
                        />
                      ) : null,
                    )}
                  </Flex>
                ) : (
                  ""
                )}
              </>
            )}
            {/* //for other settings */}
            {setting?.title?.toLowerCase() === "other" && (
              <>
                {setting?.assessment_category?.map((category) => (
                  <>
                    {category?.assessment_item?.length > 0 ? (
                      <AssessmentOtherFieldsContent
                        key={category?.id}
                        title={t(category?.title || "")}
                        fields={category?.assessment_item?.map((item) => ({
                          label: t(item?.title || ""),
                          value: item?.answer || "",
                        }))}
                      />
                    ) : null}
                  </>
                ))}
              </>
            )}
          </Flex>
        ))}

        <AssessmentOtherFieldsContent
          fields={[
            {
              label: "Author",
              value: extra || staff_name || "",
              key: "author",
              type: "textArea",
            },
          ]}
        />
      </Flex>
    )
  },
)

export const AssessmentEntityItemTable = ({
  categoryTitle,
  assessmentItems,
}: {
  categoryTitle: string
  assessmentItems: any[]
}) => {
  const { t } = useTranslation()
  const columns: ColumnsType<any[]> = [
    {
      key: 1,
      title: t(categoryTitle) || "",
      align: "center",
      width: 140,
      render: (row) => t(row?.title),
    },
    {
      key: 2,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Can ")}</span>,
      align: "center",
      render: (row: any) => (
        <Radio
          disabled
          type={"radio"}
          checked={row?.answer === "1" ? true : false}
        />
      ),
      width: 140,
    },
    {
      key: 3,
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("need a little help")}</span>
      ),
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          disabled
          type={"radio"}
          checked={row?.answer === "2" ? true : false}
        />
      ),
    },
    {
      key: 4,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("need help")}</span>,
      align: "center",
      render: (row: any) => (
        <Radio
          disabled
          type={"radio"}
          checked={row?.answer === "3" ? true : false}
        />
      ),
    },
    {
      key: 5,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Can not")}</span>,
      align: "center",
      width: 140,
      render: (row: any) => (
        <Radio
          disabled
          type={"radio"}
          checked={row?.answer === "4" ? true : false}
        />
      ),
    },
    {
      key: 6,
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Remarks")}</span>,
      align: "center",
      width: 400,
      render: (row) => row?.remarks,
    },
  ]
  return (
    <PrintFriendlyTable
      columns={columns}
      dataSource={assessmentItems || []}
      headerBg={`1px solid ${theme.colors.border}`}
      border={`1px solid ${theme.colors.border}`}
    />
  )
}
