import { CheckBox, Grid, theme } from "@project/common"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { YM_FORMAT } from "../../../constants"
const ContentTitle = styled.div`
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.colors.border};
  font-weight: 500;
`
const ItemsContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .item_content {
    border: 1px solid ${theme.colors.border};
    padding: 10px;
    &-title {
      padding-bottom: 16px;
    }
  }
`
interface Field {
  label?: string
  value?: any
  items?: any[]
  type?: string
  key?: string
}
interface FiledProps {
  label: string
  value?: any
  extra?: any
  assessment_employment_item?: Field[]
  type?: string
  key?: string
}
export const AssessmentDetailGeneralContent = ({
  fields,
  title,
}: {
  fields: FiledProps[]
  title?: string
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      {title && <ContentTitle>{t(title)}</ContentTitle>}
      {fields?.map((field, index) => (
        <Grid
          key={index}
          background
          labelContent={t(field?.label)}
          labelSpan={6}
          padding={"10px"}
          fontWeight={500}
        >
          {field?.type === "checkbox" ? (
            <CheckBox
              name={field?.key}
              label={t("hope")}
              value={field?.value}
              checked={field?.value}
            />
          ) : field?.assessment_employment_item &&
            field?.assessment_employment_item?.length ? (
            <ItemsContentContainer>
              {field?.assessment_employment_item?.map(
                (employment_item: any, index) => (
                  <div className={"item_content"} key={index}>
                    <div className={"item_content-title"}>
                      {t(`就労歴・訓練歴等${index + 1}`)}
                    </div>
                    <div>
                      <Grid
                        background
                        labelContent={t("StartDate1")}
                        labelSpan={7}
                        padding={"10px"}
                        fontWeight={500}
                      >
                        {employment_item?.start_date
                          ? dayjs(employment_item?.start_date).format(YM_FORMAT)
                          : null}
                      </Grid>
                      <Grid
                        background
                        labelContent={t("EndDate1")}
                        labelSpan={7}
                        padding={"10px"}
                        fontWeight={500}
                      >
                        {employment_item?.end_date
                          ? dayjs(employment_item?.end_date)?.format(YM_FORMAT)
                          : null}
                      </Grid>
                      <Grid
                        background
                        labelContent={t("Company name/facility name")}
                        labelSpan={7}
                        padding={"10px"}
                        fontWeight={500}
                        textAlign={"left"}
                      >
                        {employment_item?.company_name || ""}
                      </Grid>
                      <Grid
                        background
                        labelContent={t("Detail")}
                        labelSpan={7}
                        padding={"10px"}
                        fontWeight={500}
                      >
                        {employment_item?.detail || ""}
                      </Grid>
                    </div>
                  </div>
                ),
              )}
            </ItemsContentContainer>
          ) : field?.extra ? (
            field?.extra
          ) : (
            <div>{field?.value || "--"}</div>
          )}
        </Grid>
      ))}
    </div>
  )
}
