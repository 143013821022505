import { useMutation } from "react-query"
import { createOrUpdateAssessmentSetting } from "../services"
import { notification } from "antd"
import { t } from "i18next"

export const useAssessmentMutation = ({
  onCancel,
  refetch,
}: {
  onCancel: () => void
  refetch?: () => void
}) => {
  const { mutate: updateAssessmentData, isLoading: isMutating } = useMutation({
    mutationFn: createOrUpdateAssessmentSetting,
    onSuccess: () => {
      notification.success({
        message: t("Updated Successfully"),
      })
      onCancel()
      refetch()
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  return { updateAssessmentData, isMutating }
}
