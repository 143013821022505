import { Flex, Skeleton } from "antd"
import React from "react"

export const AssessmentDetailLoader = ({
  showSummaryLoader = true,
}: {
  showSummaryLoader?: boolean
}): JSX.Element => {
  return (
    <Flex vertical gap={32}>
      <Flex wrap={"wrap"} justify={"space-between"}>
        {showSummaryLoader && (
          <>
            <Flex gap={9}>
              <Skeleton.Button active />
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
            <Flex vertical gap={9}>
              <Skeleton.Button active />
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
          </>
        )}
      </Flex>
      {Array.from({ length: 5 }, (_, index) => (
        <Flex vertical gap={15} key={index}>
          <Skeleton.Button active />
          <Flex vertical gap={6}>
            <Skeleton active />
            <Skeleton active />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
